<template>
  <el-dialog title="问题定位" width="800px" :visible="locationShow" append-to-body :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="mapBlock" id="map" ref="map"></div>
    </div>
  </el-dialog>
</template>

<script>
import { mapKey } from '../../../utils/config'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'Location',
  props: {
    longitude: String,
    latitude: String,
    locationShow: Boolean
  },
  watch: {
    locationShow (val) {
      if (val) {
        this.initMap()
      }
    }
  },
  data () {
    return {
    }
  },
  created () {
    // ...
  },
  methods: {
    initMap () {
      // 绘制项目中心点
      const center = [Number(this.longitude), Number(this.latitude)]
      if (!this._map) {
        this.$nextTick(() => {
          AMapLoader.load({
            key: mapKey,
            version: '2.0',
            plugins: []
          }).then(() => {
            const map = new AMap.Map('map', {
              center: center,
              zoom: 16
            })
            this._map = map
            this.initMarker(center)
          })
        })
      } else {
        this._map.setCenter(center)
      }
    },
    initMarker (lngLat) {
      const marker = new AMap.Marker({
        position: lngLat
      })
      this._map.add(marker)
    },
    // 关闭
    closeDialog () {
      this.$emit('update:locationShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .content {
    width: 100%;
    height: 600px;
    .mapBlock {
      width: 100%;
      height: 100%;
    }
  }
</style>
