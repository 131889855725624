<template>
  <el-card>
    <div class="basic">
      <div class="content">
        <div class="item w100">
          <p class="ttl">问题描述：</p>
          <p class="info">{{info.issueContent || ''}}</p>
        </div>
        <div class="item">
          <p class="ttl">问题类型：</p>
          <p class="info">{{isFromIssue ? info.currentSafeManageName || '' : info.safeManageName || ''}}</p>
        </div>
        <div class="item">
          <p class="ttl">所属项目：</p>
          <p class="info">{{info.projectName || ''}}</p>
        </div>
        <div class="item" v-if="isFromIssue">
          <p class="ttl">问题定位：</p>
          <p type="text" class="c-default cursor" @click="locationShow = true">查看定位</p>
        </div>
        <div class="item w100">
          <p class="ttl">现场图片/视频：</p>
          <custom-upload v-if="info.fileList" v-model="info.fileList" disabled></custom-upload>
        </div>
        <div class="item">
          <p class="ttl">问题处理人：</p>
          <!-- <p class="info">{{info.handlerUserName || ''}}</p> -->
          <p class="info">{{info.currentHandleUserName || ''}}</p>
        </div>
        <div class="item">
          <p class="ttl">上报人：</p>
          <p class="info">{{info.createUserName || ''}}</p>
        </div>
        <div class="item">
          <p class="ttl">上报时间：</p>
          <p class="info">{{info.ctime || ''}}</p>
        </div>
      </div>
      <div class="buttonBlock">
        <span v-if="info.issueStatus === 1" class="bg-yellow">待反馈</span>
        <span v-else-if="info.issueStatus === 2" class="bg-yellow">待审核</span>
        <span v-else-if="info.issueStatus === 3" class="bg-green">已完成</span>
        <span v-else-if="info.issueStatus === 5" class="bg-gray">已关闭</span>
      </div>
    </div>
    <div class="line" v-if="handleList.length"></div>
    <div class="timeline">
      <template v-for="(item, index) in handleList">
        <div v-if="item.handleType >= 1 && item.handleType <= 5" class="timeline-item" :key="index">
          <div :class="'item-label ' + handleTypeList[item.handleType - 1].class">
            <div class="icon">
              <svg class="global-icon" aria-hidden="true">
                <use :xlink:href="handleTypeList[item.handleType - 1].icon"></use>
              </svg>
            </div>
            <span>{{handleTypeList[Number(item.handleType) - 1].status}}</span>
          </div>
          <el-card>
            <p class="item-title">{{item.sendUserName || ''}}</p>
            <p class="item-time">{{item.ctime || ''}}</p>
            <div class="item-content">
              <template v-if="item.handleType === 1">
                <div class="item">
                  <p class="ttl">转办至：</p>
                  <p class="info">{{item.receiveUserName || ''}}</p>
                </div>
                <div class="item">
                  <p class="ttl">问题类型：</p>
                  <p class="info">{{item.handleManageName || ''}}</p>
                </div>
              </template>
              <template v-if="item.handleType === 2">
                <div class="item w100">
                  <p class="ttl">现场图片/视频：</p>
                  <custom-upload v-if="info.fileList" v-model="item.fileList" disabled></custom-upload>
                </div>
              </template>
              <template v-if="item.handleType === 3">
                <div class="item w100">
                  <p class="ttl">整改照片/视频：</p>
                  <custom-upload v-if="info.fileList" v-model="item.fileList" disabled></custom-upload>
                </div>
              </template>
              <template v-if="item.handleType === 4">
                <div class="item w100">
                  <p class="ttl">审核结果：</p>
                  <p class="info c-green" v-if="item.approvalStatus === 3">通过</p>
                  <p class="info c-red" v-else-if="item.approvalStatus === 4">驳回</p>
                </div>
              </template>
              <template v-if="item.handleType === 5">
                <div class="item w100">
                  <p class="ttl">关闭说明：</p>
                  <p class="info">{{item.handleBak || ''}}</p>
                </div>
              </template>
              <div v-if="item.handleType !== 5" class="item w100">
                <p class="ttl">备注说明：</p>
                <p class="info">{{item.handleBak || ''}}</p>
              </div>
            </div>
          </el-card>
        </div>
      </template>
    </div>
    <template v-if="isFromIssue">
      <div class="mt20">
        <template v-if="currentUserId === info.currentHandleUserId">
          <el-button type="primary" @click="onTransfer" v-if="info.issueStatus === 1">转办</el-button>
          <el-button type="primary" @click="onFeedback" v-if="info.issueStatus === 1">反馈</el-button>
          <el-button v-if="info.issueStatus === 2" type="primary" @click="auditShow = true">审核</el-button>
        </template>
        <el-button type="info" @click="closeShow = true" v-if="[1, 2].includes(info.issueStatus)" v-show="$ButtonShow('close')">关闭</el-button>
      </div>
      <template v-if="currentUserId === info.currentHandleUserId">
        <WorkOrderTransfer :transferShow.sync="transferShow" @success="loadData" :issueId="issueId" :transInfo="transInfo"/>
        <WorkOrderFeedback :feedbackShow.sync="feedbackShow" @success="loadData" :issueInfo="issueInfo"/>
        <WorkOrderAudit :auditShow.sync="auditShow" @success="loadData" :issueId="issueId"/>
      </template>
      <WorkOrderClose v-if="[1, 2].includes(info.issueStatus)" :closeShow.sync="closeShow" @success="loadData" :issueId="issueId"/>
      <Location :locationShow.sync="locationShow" :longitude="info.longitude" :latitude="info.latitude"/>
    </template>
  </el-card>
</template>

<script>
import WorkOrderClose from './Close.vue'
import WorkOrderTransfer from './Transfer.vue'
import WorkOrderFeedback from './Feedback.vue'
import WorkOrderAudit from './Audit.vue'
import Location from './Location.vue'
export default {
  name: 'CustomDetail',
  components: { WorkOrderClose, WorkOrderTransfer, WorkOrderFeedback, WorkOrderAudit, Location },
  props: {
    issueId: String,
    // 判断是否来自于问题详情，问题详情需要展示问题定位弹框，需要按钮操作，其他不需要
    isFromIssue: {
      type: Boolean,
      default: false
    }
  },
  filters: {},
  computed: {
    currentUserId () {
      let userId = ''
      if (this.$store.state.userInfo && this.$store.state.userInfo.userId) {
        userId = this.$store.state.userInfo.userId
      } else if (window.sessionStorage.getItem('userInfo')) {
        userId = JSON.parse(window.sessionStorage.getItem('userInfo')).userId
      }
      return userId
    }
  },
  watch: {
    issueId (val) {
      if (val) {
        this.info = {}
        this.handleList = []
        this.loadData()
      }
    }
  },
  data () {
    return {
      activeNames: ['1', '2', '3'],
      handleTypeList: [{
        icon: '#iconicon_zhuanban',
        class: 'transfer',
        status: '转办'
      }, {
        icon: '#iconicon_banjie',
        class: 'complete',
        status: '办结'
      }, {
        icon: '#iconicon_fankui',
        class: 'feedback',
        status: '反馈'
      }, {
        icon: '#iconicon_shenhe',
        class: 'audit',
        status: '审核'
      }, {
        icon: '#iconicon_guanbi',
        class: 'close',
        status: '关闭'
      }],
      handleList: [],
      info: {
        fileList: []
      },
      issueInfo: {},
      closeShow: false,
      transferShow: false,
      feedbackShow: false,
      auditShow: false,
      transInfo: {
        projectId: '',
        safeManageId: '',
        currentBusinessType: '',
        currentHandleUserId: ''
      },
      locationShow: false
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.patrol.selectIssueByIssueId, {
        issueId: this.issueId
      }).then(res => {
        this.info = res || {}
        this.handleList = res.handleList || []
      })
    },
    onTransfer () {
      this.transInfo = {
        projectId: this.info.projectId,
        currentSafeManageId: this.info.currentSafeManageId,
        currentHandleUserId: this.info.currentHandleUserId
      }
      this.transferShow = true
    },
    onFeedback () {
      this.issueInfo = {
        issueId: this.issueId,
        projectId: this.info.projectId,
        currentSafeManageId: this.info.currentSafeManageId
      }
      this.feedbackShow = true
    },
    computeBusinessName (val) {
      let name = ''
      if (val) {
        const ind = this.typeList.findIndex(item => item.typeId === val)
        name = ind >= 0 ? this.typeList[ind].fullTitle : ''
      }
      return name
    }
  }
}
</script>

<style scoped lang="scss">
  .cursor {
    cursor: pointer;
  }
  .basic {
    position: relative;
    .buttonBlock {
      position: absolute;
      top: 0px;
      right: -20px;
      span {
        display: inline-block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        padding: 5px 16px;
        border-radius: 16px 0 0 16px;
      }
    }
  }
  .title{
    text-align: left;
    margin-bottom: 25px;
    font-size: 14px;
  }
  .line {
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #EAEEF5;
  }
  .content, .timeline .item-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1500px;
    &.item-content {
      width: 100%;
    }
    .item {
      display: flex;
      width: 320px;
      font-size: 14px;
      line-height: 22px;
      margin:0 40px 20px 0;
      .ttl {
        width: auto;
        color: #000;
        white-space: nowrap;
      }
      .info {
        color: #666;
      }
      &.w100 {
        width: 100%;
      }
    }
  }

  .timeline {
    .timeline-item {
      position: relative;
      display: flex;
      margin-bottom: 20px;
      &:not(:last-child):before {
        content: '';
        position: absolute;
        top: 15px;
        left: 40px;
        width: 2px;
        height: calc(100% + 20px);
        background: #dedede;
        z-index: 1;
      }
      .item-label {
        position: relative;
        display: flex;
        align-content: center;
        width: 68px;
        height: 28px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 28px;
        background: #36CBCB;
        border-radius: 28px 0 0 28px;
        margin-top: 15px;
        z-index: 2;
        flex-shrink: 0;
        .icon {
          width: 24px;
          height: 24px;
          color: #36CBCB;
          line-height: 24px;
          text-align: center;
          background: #ffffff;
          border-radius: 50%;
          margin: 2px 6px 0 2px;
          .global-icon {
            font-size: 16px;
          }
        }
        &.feedback {
          background: #FFD300;
          .icon {
            color: #FFD300;
          }
        }
        &.audit {
          background: #898989;
          .icon {
            color: #898989;
          }
        }
        &.close {
          background: #8C8C8C;
          .icon {
            color: #8C8C8C;
          }
        }
      }
      .el-card {
        flex-grow: 2;
        background: #FAFAFA;
        ::v-deep {
          .el-card__body {
            padding-bottom: 0;
          }
        }
        .item-title, .item-time {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 22px;
          &.item-time {
            font-family: HelveticaNeue;
            color: #999999;
            margin-top: 2px;
          }
        }
        .item-content {
          margin-top: 20px;
        }
      }
    }
  }
</style>
